import { FC } from 'react';
import { Check, X } from 'react-feather';
import * as Icons from 'react-feather';

export const VistoCheckCircle: FC<
  { iconProps?: Icons.IconProps } & JSX.IntrinsicElements['div']
> = ({ className, iconProps }) => (
  <div
    className={`w-6 h-6 min-w-6 min-h-6 rounded-full bg-green-200 flex justify-center items-center text-green-800 ${
      className ?? ''
    }`}
  >
    <Check size={12} strokeWidth={4} {...iconProps} />
  </div>
);

export const VistoXCircle: FC<
  { iconProps?: Icons.IconProps } & JSX.IntrinsicElements['div']
> = ({ className, iconProps }) => (
  <div
    className={`w-6 h-6 min-w-6 min-h-6 rounded-full bg-red-200 flex justify-center items-center text-red-800 ${
      className ?? ''
    }`}
  >
    <X size={12} strokeWidth={4} {...iconProps} />
  </div>
);

export const VistoAlertCircle: FC<JSX.IntrinsicElements['div']> = ({
  className,
}) => (
  <div
    className={`w-6 h-6 min-w-6 min-h-6 rounded-full bg-red-200 flex justify-center items-center text-red-800 ${
      className ?? ''
    }`}
  >
    <span className="ml-0.5 fs14">!</span>
  </div>
);

export const MapleLeaf: FC<JSX.IntrinsicElements['div']> = ({ className }) => (
  <div className={`w-6 ${className ?? ''}`}>
    <img src="/maple-leaf.svg" alt="Maple leaf" />
  </div>
);

export const VistoIcon: FC<
  { color?: string } & JSX.IntrinsicElements['div']
> = ({ className, color = '#1e6680' }) => (
  <div className={`w-6 ${className ?? ''}`}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 370 406"
      fill={color}
    >
      <path
        id="Layer"
        className="s0"
        d="m-0.6 134.4l242.9-0.8-54 66.5-73.9-0.5 51.2 63.1 204.6-262.7-203.6 405.3z"
      />
    </svg>
  </div>
);

export const Graduation: FC<
  { color?: string } & JSX.IntrinsicElements['div']
> = ({ className, color = '#1e6680', style }) => (
  <div className={`w-6 ${className ?? ''}`} style={style}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 417 295"
      fill={color}
    >
      <g id="Layer">
        <path
          id="Layer"
          fillRule="evenodd"
          className="s0"
          d="m399.5 179.8c7.8 2.8 13.9 9.1 16.3 17 2.5 8 1 16.6-4 23.3-4.9 6.7-12.7 10.7-21.1 10.7-8.3 0-16.1-4-21.1-10.7-4.9-6.7-6.4-15.3-4-23.3 2.5-7.9 8.5-14.2 16.4-17v-49.7l-65.6 26.5v75.8c0 5.1-1.5 10.1-4.3 14.4-2.9 4.3-6.8 7.7-11.5 9.7l-83.2 36.1c-6.6 2.9-14.2 2.9-20.9 0l-83.1-36.1c-4.7-2-8.7-5.4-11.5-9.7-2.8-4.3-4.3-9.3-4.3-14.4v-75.8l-81.2-32.8c-6.5-2.6-11.7-7.7-14.4-14.2-2.7-6.5-2.7-13.7 0-20.2 2.7-6.5 7.9-11.6 14.4-14.2l180.8-73c6.3-2.6 13.3-2.6 19.6 0l180.8 73c6.3 2.5 11.3 7.3 14.1 13.5 2.8 6.2 3.1 13.2 0.8 19.6-2.3 6.3-6.9 11.6-13 14.6v56.9zm-376.5-72.2l180.7 73c2.1 0.9 4.5 0.9 6.6 0l180.8-73c3.3-1.3 5.4-4.5 5.4-8.1 0-3.6-2.1-6.8-5.4-8.1l-180.8-73c-2.1-0.9-4.5-0.9-6.6 0l-180.7 73c-3.4 1.3-5.6 4.5-5.5 8.1-0.1 3.6 2.1 6.8 5.5 8.1zm275.9 56.1l-82.1 33.1c-6.3 2.6-13.3 2.6-19.6 0l-82.1-33.1v68.7c0 3.5 2.1 6.7 5.3 8l83.1 36.2c2.2 0.9 4.8 0.9 7 0l83.1-36.2c3.2-1.3 5.3-4.5 5.3-8zm100.6 40.8c0-3.5-2.1-6.7-5.4-8.1-3.3-1.3-7-0.6-9.5 1.9-2.5 2.5-3.3 6.3-1.9 9.5 1.3 3.3 4.5 5.5 8.1 5.5 4.8-0.1 8.7-4 8.7-8.8z"
        />
      </g>
    </svg>
  </div>
);
